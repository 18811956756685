$top-bar-background-color: #c94b32;
$logo-background-color: #9a283f;
$body-background-color: #F4F9FD;

body.skin-blue{
  header.main-header .navbar{
    background-color: $top-bar-background-color;
  }
  .main-header .logo:hover,
  .main-header .logo {
    background-color: $logo-background-color;
    height: 52px;
  }
  .wrapper,
  .main-sidebar,
  .left-side{
    background-color: $body-background-color;
  }
  .content-wrapper{
    background-color: $body-background-color;
  }
  .main-sidebar,
  .left-side{
    a{
      color: #666;
    }
    li {
      a:hover,
      a:active,
      a:focus,
      &.active a{
        color: #333;
        background-color: darken($body-background-color,5%);
        border-left-color: $logo-background-color;
      }

      &.dropdown a, &.dropdown.open a{
        background-color: $body-background-color;
        color: #666;
        &:hover,
        &:active,
        &:focus{
          color: #333;
          background-color: darken($body-background-color,5%);
          border-left-color: $logo-background-color;
        }
      }
      .dropdown-menu{
        background-color: $body-background-color;
        margin-left: 10px;
        border-radius: 0;
        border-width: 0;
        box-shadow: 0px 1px 2px darken($body-background-color, 90%);
      }
    }
  }
  .user-panel>.info,
  .user-panel>.info>a {
    color: #333;
  }
}

a {
  color: $top-bar-background-color;
  &:hover {
    color: darken($top-bar-background-color, 10%);
  }
}

.avatar{
  background-color: white;
}

.mdc-layout-grid__cell--span-8, .mdc-tab-bar-scroller {
  left: 55px;
}