@import "/var/www/alteLeipziger2/project/alteLeipziger/web/src/theme/_variables.scss";@import "/var/www/alteLeipziger2/project/alteLeipziger/web/src/dashboard/scss/dashboard.scss";
.left-nav-items-groups {
  margin-top: 40px;
}

.section-header-width-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 32px;
  border-bottom: 1px solid #eaecf2;
}

.button-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.button-group-right {
  justify-content: flex-end;
}

.button-group-center {
  justify-content: center;
}

.button-group-space-between-padding-y {
  justify-content: space-between;
  padding: 20px 0;
}

.switch-with-text-container {
  display: flex;
}

.input-with-text-and-icon {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 20px;
}

.input-with-text-and-icon-content {
  width: 100%;
  display: flex;
}

.input-with-text-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.input-title {
  font-size: 12px;
  line-height: 24px;
  color: #747c88;
}

.input-large-title {
  font-weight: 500;
  font-size: 16px;
  color: black;
  padding-bottom: 5px;
  padding-top: 5px;
}

.copy-input-to-clipboard {
  display: grid;
  grid-template-columns: 20fr 1fr;
  column-gap: 10px;
  flex-direction: row;
}

.input-with-text-and-icon-desc {
  padding: 10px 8px 10px 0;
  font-size: 14px;
}

.alert-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  font-family: inherit;
}

.Toastify__toast-container--bottom-right  {
  .Toastify__toast--success,
  .Toastify__toast--warning {
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 16px;
  }

  .Toastify__close-button--success,
  .Toastify__close-button--warning {
    color: black;
  }
}


.dialog-right-header-title {
  max-width: 300px;
  word-break: keep-all;
}

.button-group-space-between-pt {
  justify-content: space-between;
  padding-top: 20px;
}

.button-group-space-between {
  justify-content: space-between;
}

.button-group-right-pt {
  justify-content: flex-end;
  padding-top: 20px;
}

.button-group-right-dialog-one-btn {
  justify-content: flex-end;
  padding: 20px 0 24px;
  margin: 0 24px;
}

.button-group-right-dialog-two-btn {
  justify-content: space-between;
  padding: 20px 0 24px;
  margin: 0 24px;
}

.button-group-right-dialog-full-width {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.inline-navigation {
  display: flex;
  gap: 24px;
  font-size: 14px;
  overflow-x: scroll;
}

.inline-navigation::-webkit-scrollbar {
  display: none;
}

.download-area {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fbfdff;
  border: 1px dashed #d1d5dd;
  border-radius: 8px;
  color: #9096a0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.intl-tel-input {
  width: 100%;
}

.ticket {
  background-color: #f3f5f9;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #747c88;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
}

.ticket-close-icon {
  display: flex;
  cursor: pointer;
}

.dialog-without-buttons-content {
  min-height: 232px;
  padding-top: 17px;
}

.checkbox-with-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  color: #747C88;
}

.header-input-search,
.header-date-range-and-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header-input-search-cta {
  display: flex;
  gap: 16px;
}

.table-head {
  background-color: #f8fafb;
  color: #778592;
  font-size: 12px;
  border-radius: 6px;
  height: 32px;
  font-weight: 500;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 900px) {
  .copy-input-to-clipboard {
    grid-template-columns: 1fr;
  }

  .section-header-width-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 35px;
    margin-bottom: 25px;
    border-bottom: 1px solid #eaecf2;
  }

  .information {
    font-size: 14px;
    padding: 15px;
    gap: 14px;
  }

  .checkbox-with-buttons {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .header-input-search {
    flex-direction: column;
    gap: 20px;
  }

  .header-input-search.header-date-range-and-search { 
    align-items: left;
    width: calc(100vw - 80px);
  }

  .header-input-search-cta {
    flex-direction: column-reverse;
  }
}
